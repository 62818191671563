import React from 'react';
import { graphql, Link } from 'gatsby';
import { FiHome, FiPackage } from 'react-icons/fi';
import { BiStore } from 'react-icons/bi';
import { GatsbyImage } from 'gatsby-plugin-image';
import tw from 'twin.macro';

import { Hero } from '../../components/Hero/';
import PageLayout from '../../components/PageLayout/';
import SEO from '../../components/seo';
import { Badges } from '../../components/Badges/';
import {
  CTASubTitle,
  CTATitle,
  CTAWrapper,
  FAQContainer,
  FAQGrid,
  FAQHeader,
  FAQLinkContainer,
  FAQLinkImageContainer,
  FAQLinks,
  FAQWrapper,
  LinkHeader,
  LinkSubHeader,
} from '../../styles/faq-styles';

const FAQIndex = ({ data: { Postbote, FAQDropPoint, FAQBesteller, FAQBusiness2 } }) => {
  return (
    <PageLayout className="bg-white">
      <SEO title="Häufig gestellte Fragen" />
      <Hero
        image={Postbote}
        title="Du hast Fragen zu DropFriends?"
        subTitle="Hier findest Du die Antworten">
        <div className="font-display font-xl tracking-wide">
          DropFriends-App downloaden & Haushaltskasse aufbessern.
        </div>

        <Badges />
      </Hero>
      <FAQWrapper>
        <FAQContainer>
          <FAQHeader>Finde alle Antworten zu Deinen Fragen</FAQHeader>
          <FAQGrid>
            <FAQLinkContainer>
              <FAQLinkImageContainer>
                <GatsbyImage
                  image={FAQDropPoint.childImageSharp.gatsbyImageData}
                  className="w-full h-full"
                  objectFit="cover"
                  objectPosition="center center"
                  loading="eager"
                  alt=""
                />
              </FAQLinkImageContainer>
              <FAQLinks to="/faq/droppoint" tw="xl:my-48">
                <FiHome size="48px" className="mb-2" />
                <LinkHeader>DropPoint</LinkHeader>
                <LinkSubHeader>
                  Wissenswertes für unsere Paketannahmestellen.
                  <br />
                  <br />
                </LinkSubHeader>
              </FAQLinks>
            </FAQLinkContainer>
            <FAQLinkContainer>
              <FAQLinkImageContainer>
                <GatsbyImage
                  image={FAQBesteller.childImageSharp.gatsbyImageData}
                  className="w-full h-full"
                  objectFit="cover"
                  objectPosition="center center"
                  loading="eager"
                  alt=""
                />
              </FAQLinkImageContainer>
              <FAQLinks to="/faq/besteller">
                <FiPackage size="48px" className="mb-2" />
                <LinkHeader>Besteller</LinkHeader>
                <LinkSubHeader>
                  Wissenswertes rund um den Empfang und die Übergabe Deiner Pakete.
                  <br />
                  <br />
                </LinkSubHeader>
              </FAQLinks>
            </FAQLinkContainer>
            <FAQLinkContainer>
              <FAQLinkImageContainer>
                <GatsbyImage
                  image={FAQBusiness2.childImageSharp.gatsbyImageData}
                  className="w-full h-full"
                  objectFit="cover"
                  objectPosition="center center"
                  loading="eager"
                />
              </FAQLinkImageContainer>
              <FAQLinks to="/faq/business">
                <BiStore size="48px" className="mb-2" />
                <LinkHeader>Unternehmer</LinkHeader>
                <LinkSubHeader>
                  Wissenswertes rund um unsere Angebote für Dein Unternehmen
                  <br />
                  <br />
                </LinkSubHeader>
              </FAQLinks>
            </FAQLinkContainer>
          </FAQGrid>
        </FAQContainer>
      </FAQWrapper>

      <CTAWrapper>
        <CTATitle>Jetzt die DropFriends-App downloaden.</CTATitle>

        <CTASubTitle>Erhältlich im App Store und im Google Play Store.</CTASubTitle>

        <Badges className="mt-4" />
      </CTAWrapper>
    </PageLayout>
  );
};

export const query = graphql`
  {
    Postbote: file(relativePath: { eq: "postbote.jpg" }) {
      childImageSharp {
        gatsbyImageData(sizes: "100vw", quality: 100, placeholder: TRACED_SVG, layout: FULL_WIDTH)
      }
    }
    FAQDropPoint: file(relativePath: { eq: "FAQ-DropPoint.png" }) {
      childImageSharp {
        gatsbyImageData(sizes: "50vw", quality: 100, placeholder: TRACED_SVG, layout: FULL_WIDTH)
      }
    }
    FAQBesteller: file(relativePath: { eq: "FAQ-Besteller.jpg" }) {
      childImageSharp {
        gatsbyImageData(sizes: "50vw", quality: 100, placeholder: TRACED_SVG, layout: FULL_WIDTH)
      }
    }
    FAQBusiness2: file(relativePath: { eq: "FAQ-Business.png" }) {
      childImageSharp {
        gatsbyImageData(sizes: "50vw", quality: 100, placeholder: TRACED_SVG, layout: FULL_WIDTH)
      }
    }
  }
`;

export default FAQIndex;
